
import { Component, Vue, Inject } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";
import { toNumber } from "@/tools";

import CreateUi from "@/components/Core/Create/CreateUI.vue";

import { ISentenceService } from "@/services";

@Component({
  components: {
    CreateUi,
  },
  data: () => {
    return {
      toNumber,
    };
  },
})
export default class SentenceCreate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get sentenceService() {
    return this.container.resolve<ISentenceService>(S.SENTENCE_SERVICE);
  }

  loading: boolean = false;
  method: string | null = null;
  target: string | null = null;
  sentenceValue: string | null = null;
  number: number | null = null;
  emptySentenceRule: boolean | string = true;

  async create() {
    this.triggerValidation();
    try {
      if (this.emptySentenceRule == true) {
        this.loading = true;
        const sentenceCreated = await this.sentenceService.create({
          number: this.number,
          method: this.method,
          target: this.target,
          sentenceValue: this.sentenceValue,
        });
        this.$emit("create", sentenceCreated);
      }
    } finally {
      this.loading = false;
    }
  }

  updateNumber(number: number) {
    this.number = number;
    this.triggerValidation();
  }

  updateMethod(method: string) {
    this.method = method;
    this.triggerValidation();
  }

  updateTarget(target: string) {
    this.target = target;
    this.triggerValidation();
  }

  updateValue(value: string) {
    this.sentenceValue = value;
    this.triggerValidation();
  }

  triggerValidation() {
    this.emptySentenceRule =
      this.method || this.target || this.sentenceValue || this.number != null
        ? true
        : "Un des champs doit être renseigné";
  }
}
